/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('UserController', ['$scope', '$http', '$state', '$compile', '$filter', 'DTOptionsBuilder', 'DTColumnBuilder', 'CommonAdminService', 'apiAdmin', '$rootScope','HeaderService','TableFilter', 'RestService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'Language', 'AdminFactory', 'CommonService', 'AnnexaModalFactory', 'globalModals', 'HelperService', 'dialogs', 'EmailFactory', 'ThirdFactory', 'DialogsFactory', 'GlobalDataFactory', function ($scope, $http, $state, $compile, $filter, DTOptionsBuilder, DTColumnBuilder, CommonAdminService, apiAdmin, $rootScope, HeaderService, TableFilter, RestService, CommonAdminModals, AnnexaFormlyFactory, Language, AdminFactory, CommonService, AnnexaModalFactory, globalModals, HelperService, dialogs, EmailFactory, ThirdFactory, DialogsFactory, GlobalDataFactory) {
        $rootScope.loading(false);
        $scope.languageColumn = Language.getActiveColumn();

        var selectYesNo = [
            { id: 1, name: 'global.literals.yes' },
            { id: 2, name: 'global.literals.no' }
        ];

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement('identifier', 'text', 0, 'global.literals.user');
        $scope.tfilter.addElement('name', 'text', 1, 'global.literals.name');
        $scope.tfilter.addElement('positions', 'select', 2, 'global.literals.positions', 3, HelperService.addAllSelectAngularTranslate(AdminFactory.positions), true, $scope.languageColumn, true).setFilterCallAux(true);
        $scope.tfilter.addElement('status', 'select', 3, 'global.literals.active', 3, HelperService.addAllSelectAngularTranslate(selectYesNo), true, 'name', true).setFilterCallAux(true);
        $scope.tfilter.addElement('certificate', 'select', 4, 'global.literals.certificate_userAdmin', 3, HelperService.addAllSelectAngularTranslate(selectYesNo), true, 'name', true).setFilterCallAux(true);
        $scope.tfilter.addElement('createdDate', 'dateRange', 5, 'global.literals.createdDate').setFilterCallAux(true);
        $scope.tfilter.addElement('expiryDate', 'dateRange', 6, 'global.literals.expiryDate').setFilterCallAux(true);
        $scope.tfilter.addElement('section', 'text', 7, 'global.literals.section').setFilterCallAux(true).setUserChosenFilter(true);
        $scope.tfilter.addElement('role', 'text', 8, 'global.literals.role').setFilterCallAux(true).setUserChosenFilter(true);
        $scope.tfilter.addElement('profile', 'select', 9, 'global.literals.profile', 3, HelperService.addAllSelectAngularTranslate(GlobalDataFactory.allProfiles), true, $scope.languageColumn, true).setFilterCallAux(true).setUserChosenFilter(true);

        $scope.dtDefUser = new TableData('common','User','./api/common/byfilter','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','identifier',[[2, 'asc']]);

        var showEnableAction = function (data, type, full, meta) {
            if(full.expiryDate == null || new Date() < new Date(full.expiryDate)){
                return false;
            } else {
                return true;
            }
        }

        var showDisableAction = function (data, type, full, meta) {
            if(full.expiryDate == null || new Date() < new Date(full.expiryDate)){
                return true;
            } else {
                return false;
            }
        }

        $scope.filterData = { userStatus: ''};
        var userActiveColumn = new ActiveByDateColumn($scope, 'filterData', 'userStatus',
            [
                new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                new IconSearchOption(1, 1, 'm-l-xs fa fa-circle text-success', 'm-l-xs fa fa-circle text-success', 'global.commonAdmin.filter.statuses.active'),
                new IconSearchOption(2, 2, 'm-l-xs fa fa-circle text-danger', 'm-l-xs fa fa-circle text-danger', 'global.commonAdmin.filter.statuses.inactive'),
            ], 'tableUser');

        var userNameColumn = new UserColumn($filter, 'global.commonAdmin.datatable.name', true);
        var userCreateDateColumn = new DateColumn($filter,'global.commonAdmin.datatable.createDate');
        var userExpiryDateColumn = new DateColumn($filter,'global.commonAdmin.datatable.expiryDate');
        var userPhoneColumn = new UserPhoneColumn($filter, '', true);
        var userEmailColumn = new UserEMailColumn($filter, '', true);
        var userActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                  new ActionButton('global.literals.see','openUser([data])','fa-eye'),
                                                  [
                                                      new ActionButton('global.commonAdmin.datatable.options.disable', 'disabledUser([data])',undefined,undefined, showDisableAction),
                                                      new ActionButton('global.commonAdmin.datatable.options.enable', 'enabledUser([data])',undefined,undefined, showEnableAction)
                                                  ]);

        var resetPasswordRender = function(data, type, full, meta) {
            var content = '';
            if(full && full.havePassword) {
                var tooltip = $filter('translate')('global.literals.resetPassword');

                content += '<a ng-click="resetPassword(' + data + ', \'' + full.email + '\')">';
                content += '    <i class="fa fa-key fa-lg" uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"></i>';
                content += '</a>'
            }
            return content;
        }

        $scope.resetPassword = function(id, email) {
            if(!email || email == 'null') {
                var dlg = dialogs.error(
                    '',
                    $filter('translate')('global.errors.resetPsswordEmail'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );
            } else {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.resetPassword'),
                    $filter('translate')('global.literals.confirmResetPassword'),
                    {
                        animation: true,
                        background: 'static',
                        keyboard: true,
                        size: ''
                    }
                );

                dlg.result.then(function(btn) {
                    var translations = {
                        "Subject": $filter('translate')('global.literals.resetPasswordSubject'),
                        "Text": $filter('translate')('global.literals.resetPasswordText')
                    };

                    AdminFactory.resetPassword(id, translations)
                        .then(function(data) {
                            var ok = dialogs.notify(
                                '',
                                $filter('translate')('global.literals.resetPasswordOk'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            )
                        },function (error) {
                            mensaje = $filter('translate')('global.errors.resetPasswordError')
                            if(error && error.data && error.data.message == 'Mail max size exceded not found') {
                                mensaje = $filter('translate')('global.errors.errorSendMailMaxSize');
                            }else if(error && error.data && error.data.message == 'Send mail service is not active in annexa admin') {
                                mensaje = $filter('translate')('global.errors.errorSendMailDisabled');
                            }else if(error && error.data && error.data.message == 'Max time waited exceded') {
                                mensaje = $filter('translate')('global.errors.errorSendMailMaxTime');
                            }else if(error.data.message == 'Password invalid format exception.'){
                        		mensaje = GlobalDataFactory.passwordAlertMessage;
                    		}else{
                                mensaje = $filter('translate')('global.errors.errorSendMail');
                            }

                            var dlg = dialogs.error(
                                '',
                                mensaje,
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );

                        }).catch(function(error) {
                            var dlg = dialogs.error(
                                '',
                                $filter('translate')('global.errors.resetPasswordError'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                }, function (btn) {});

            }
        }

        var columns = [
            DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
            DTColumnBuilder.newColumn('expiryDate').withOption('width', '5%').withTitle(userActiveColumn.getTitle()).renderWith(userActiveColumn.getRender).notSortable(),
            DTColumnBuilder.newColumn('identifier').withTitle($filter('translate')('global.literals.user')),
            DTColumnBuilder.newColumn('name').withTitle(userNameColumn.getTitle()).renderWith(userNameColumn.getRender),
            DTColumnBuilder.newColumn('acronym').withOption('width', '10%').withTitle($filter('translate')('global.literals.acronym')),
            DTColumnBuilder.newColumn('language.acronym').withOption('width', '5%').withTitle($filter('translate')('global.literals.language')),
            DTColumnBuilder.newColumn('createdDate').withOption('width', '10%').withTitle(userCreateDateColumn.getTitle()).renderWith(userCreateDateColumn.getRender),
            DTColumnBuilder.newColumn('expiryDate').withOption('width', '10%').withTitle(userExpiryDateColumn.getTitle()).renderWith(userExpiryDateColumn.getRender),
            DTColumnBuilder.newColumn('phone').withOption('width', '5%').renderWith(userPhoneColumn.getRender).notSortable(),
            DTColumnBuilder.newColumn('email').withOption('width', '5%').renderWith(userEmailColumn.getRender).notSortable(),
        ];

        if(!$rootScope.externalAuthentication && ($rootScope.app.configuration.security_method.value == 'DATABASE' || $rootScope.app.configuration.security_method.value == 'LDAPANDDATABASE' || $rootScope.app.configuration.security_method.value == 'DATABASEANDLDAP') ) {
            columns.push(DTColumnBuilder.newColumn('id').withOption('width', '5%').renderWith(resetPasswordRender).notSortable());
        }

        columns.push(DTColumnBuilder.newColumn('id').withOption('width', '120px').withOption('className', 'text-center').withTitle(userActionsColumn.getTitle()).notSortable().renderWith(userActionsColumn.getRender));

        $scope.getFilterCallAux = function(){
            var filtercallaux = $scope.tfilter.getFilterCallAux();
            if($scope.filterData.userStatus){
                filtercallaux.status = {id:$scope.filterData.userStatus};
            }
            filtercallaux.langColumn = Language.getActiveColumn();
            return filtercallaux;
        }
        $scope.tableUser = {
            dtColumns: columns,
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: $scope.getFilterCallAux(),
            reloadInternalData: function(resetPaging){
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = $scope.getFilterCallAux();
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        };

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.users') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-users','fa fa-plus','global.commonAdmin.newUser',undefined,'newUser'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-users','fa fa-upload','global.literals.import',undefined,'importUsers')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.doSearchFilter = function(){
            $scope.tableUser.reloadInternalData(true);
        }

        $scope.newUser = function() {
            var secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec'").toArray();
            var modal = angular.copy(CommonAdminModals.userNew);
            angular.forEach(modal.tabs, function (value, key) {
                modal.tabs[key].annexaFormly.model = {};
                modal.tabs[key].annexaFormly.options = {};
            });
            modal.tabs[0].annexaFormly.model.row_photo = { haveSec: ((secModule && secModule.length > 0)? true : false) };
            modal.tabs[0].annexaFormly.fields[4].fieldGroup[2].templateOptions.options = $rootScope.app.languagedef;
            modal.tabs[0].annexaFormly.fields[5].fieldGroup[0].templateOptions.labelProp = $scope.languageColumn;
            modal.tabs[0].annexaFormly.fields[5].fieldGroup[0].templateOptions.options = new SortedArray(AdminFactory.positions, $scope.languageColumn).sort();
            modal.tabs[1].annexaFormly.fields[0].fieldGroup[0].templateOptions.labelProp = $scope.languageColumn;
            modal.tabs[1].annexaFormly.fields[0].fieldGroup[0].templateOptions.options = new SortedArray(AdminFactory.sections, $scope.languageColumn).sort();
            modal.tabs[1].annexaFormly.fields[1].fieldGroup[0].templateOptions.labelProp = $scope.languageColumn;
            modal.tabs[1].annexaFormly.fields[1].fieldGroup[0].templateOptions.options = new SortedArray(AdminFactory.roles, $scope.languageColumn).sort();
            modal.tabs[1].profiles = new SortedArray(AdminFactory.profiles, $scope.languageColumn).sort();
            modal.tabs[1].roles = new SortedArray(AdminFactory.roles, $scope.languageColumn).sort();
            modal.tabs[1].userProfiles = [];
            modal.tabs[1].inserted = {};
            modal.tabs[1].requiredText = $filter('translate')('global.validation.required');
            modal.tabs[1].duplicateEntityType = $filter('translate')('global.validation.duplicateValue');
            AnnexaFormlyFactory.showModal('modalNewUser', modal, $scope.saveUser, false);
        }
        
        $scope.importUsers = function () {  
        	AdminFactory.importDataAdmin($scope, "USERS", $scope.tableUser);
        }

        $scope.assignUserToThird = function($modal) {
            console.log("assignUserToThird");
        }

        $scope.saveUser = function ($modal) {
            var passwordOk = true;
            if($modal.tabs[0].annexaFormly.model.row1.password && $modal.tabs[0].annexaFormly.model.row1.repassword) {
                if($modal.tabs[0].annexaFormly.model.row1.password != $modal.tabs[0].annexaFormly.model.row1.repassword) {
                    $modal.alerts.push({ msg: 'global.validation.passwordConcordance'})
                    passwordOk = false;
                }
            }

            if(passwordOk && $modal.annexaFormly.form.$valid) {
                var model = {};
                var tab1 = $modal.tabs[0].annexaFormly.model;
                var tab2 = $modal.tabs[1].annexaFormly.model;
                tab2.userProfiles = $modal.tabs[1].userProfiles || [];
                tab2.userDefaultProfiles = $modal.tabs[1].userDefaultProfiles || [];
                var tab3 = $modal.tabs[2].certificates;

                if(tab1.row1) {
                    if(tab1.row1.identifier) {
                        model.identifier = tab1.row1.identifier;
                    }

                    if(tab1.row1.password && tab1.row1.repassword) {
                        if(tab1.row1.password == tab1.row1.repassword) {
                            model.password = tab1.row1.password;
                        } else {
                            $modal.alerts.push({ msg: 'global.validation.passwordConcordance'})
                        }
                    }
                }
                if (tab1.row_photo) {
                    if (tab1.row_photo.photo) {
                        model.photo = {
                            file: CommonService.base64ArrayBuffer(tab1.row_photo.photo.file),
                            fileName: tab1.row_photo.photo.fileName,
                            fileType: tab1.row_photo.photo.fileType
                        };
                    }
                }

                if(tab1.row2) {
                    if(tab1.row2.name) {
                        model.name = tab1.row2.name;
                    }

                    if(tab1.row2.surename1) {
                        model.surename1 = tab1.row2.surename1;
                    }

                    if(tab1.row2.surename2) {
                        model.surename2 = tab1.row2.surename2;
                    }
                }

                if(tab1.row3) {
                    if(tab1.row3.cellPhone) {
                        model.cellPhone = tab1.row3.cellPhone;
                    }

                    if(tab1.row3.extension) {
                        model.extension = tab1.row3.extension;
                    }

                    if(tab1.row3.phone) {
                        model.phone = tab1.row3.phone;
                    }
                }

                if(tab1.row4) {
                    if(tab1.row4.acronym) {
                        model.acronym = tab1.row4.acronym;
                    } else {
                        model.acronym = '';
                    }

                    if(tab1.row4.email) {
                        model.email = tab1.row4.email;
                    }

                    if(tab1.row4.language) {
                        model.language = tab1.row4.language;
                    }
                }
                model.positions = [];

                if(tab1.row5) {
                    if(tab1.row5.positions) {
                        model.positions = tab1.row5.positions;
                    }
                    if(tab1.row5.identificationNumber) {
                    	model.identificationNumber = tab1.row5.identificationNumber;
                    }
                }
                
                model.sections = [];
                if(tab2.row1) {
                    if(tab2.row1.sections) {
                        model.sections = tab2.row1.sections;
                    }
                }

                model.roles = [];
                if(tab2.row2) {
                    if(tab2.row2.roles) {
                        model.roles = tab2.row2.roles;
                    }
                }

                model.userProfiles = []
                angular.forEach(tab2.userProfiles, function (userRole) {

                })
                model.userProfiles = tab2.userProfiles;
                model.userDefaultProfiles = tab2.userDefaultProfiles;


                model.certificates = [];
                if(tab3) {

                    angular.forEach(tab3, function (value) {
                        model.certificates.push( {file: CommonService.base64ArrayBuffer(value.file), fileName: value.fileName.trim().replace(/[^A-Za-z0-9_.()-]/g,""), fileType: value.fileType });
                    })
                }
                createUserFunction(model, $modal);
            }
        }

        var createUserFunction = function(user, $modal, modalAux){

            AdminFactory.newUser(user).then(function(data) {
                if(modalAux){
                    modalAux.close();
                }
                $modal.close();
                $scope.tableUser.reloadInternalData(true);
            }).catch(function (error) {
                if(error && error.data && error.data.message) {
                    if(error.data.message == 'Other user associated with this third not found') {
                        $modal.alerts.push({msg: 'global.literals.otherUserWithThird'});
                    }else if(error.data.message == 'Not create user to repository not found') {
                        $modal.alerts.push({msg: 'global.errors.createUserAlfrescoError'});
                    }else if(error.data.message == 'Other user uses these abbreviation not found'){
                        $modal.alerts.push({msg: 'global.literals.otherUserWhithAcronym'});
                    }else if(error.data.message == 'Other user uses these identifier not found'){
                        $modal.alerts = [{msg: 'global.literals.otherUserWhithIdentifier'}];
                    }else if(error.data.message == 'Password invalid format exception.'){
                        $modal.alerts = [{msg: GlobalDataFactory.passwordAlertMessage}];
                    }else{
                        $modal.alerts.push({msg: 'global.errors.unknownNew'});
                    }
                }else{
                    $modal.alerts.push({msg: 'global.errors.unknownNew'});
                }
            });
        }

        var transformProfiles = function (userProfiles, property, literal) {
            var profiles = [];

            angular.forEach(userProfiles, function (val) {
                if(val[property]) {
                    if (val[property] instanceof Object) {
                        val[property] = {id: val[property].id, description: literal + val[property].id};
                    } else {
                        val[property] = {id: val[property], description: literal + val[property]};
                    }
                }
                profiles.push(val);
            });

            return profiles;
        };

        var transformProfilesToServerProfile = function (userProfiles, property) {
            var profiles = [];
            angular.forEach(userProfiles, function (val) {
                if(val[property] && val[property].id){
                    val[property] = val[property].id;
                }
                profiles.push(val);
            });

            return profiles;
        };

        var callUpdateUserService = function (model, self, certificateToAdd, photoToAdd, isSection, isRole, isPosition) {
            model.userProfiles = transformProfilesToServerProfile(model.userProfiles,'type');
            model.userDefaultEntityProfiles = transformProfilesToServerProfile(model.userDefaultEntityProfiles,'entityType');
            if(!isSection) {
                model.userSections = [];
                angular.forEach(model.sections, function (val) {
                    model.userSections.push({section: val, user: {id: $rootScope.LoggedUser.id}});
                });
            }
            if(!isRole) {
                model.userRoles = [];
                angular.forEach(model.roles, function (val) {
                    model.userRoles.push({role: val, user: {id: $rootScope.LoggedUser.id}});
                });
            }
            if(!isPosition) {
                model.userPositions = [];
                _.forEach(model.positions, function (val) {
                    model.userPositions.push({ position: val, user: { id: $rootScope.LoggedUser.id }});
                });
            }
            AdminFactory.updateUser(model, certificateToAdd, photoToAdd)
                .then(function (data) {
                    self.data = JSOG.decode(data.data);
                    self.data.userProfiles = transformProfiles(self.data.userProfiles, 'type','commonAdmin.modal.user.');
                    self.definitionProfiles.items = self.data.userProfiles;
                    self.data.userDefaultEntityProfiles = transformProfiles(self.data.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                    $scope.callResetList(self);
                    $scope.getUserMailAlerts(self);
                    self.definitionDefaultProfiles.items = self.data.userDefaultEntityProfiles;
                    self.definitionProfiles.containerObject = self.data;
                    self.definitionDefaultProfiles.containerObject = self.data;
                    $scope.tableUser.reloadInternalData(false);
                }).catch(function (error) {
                    model.userProfiles = transformProfiles(model.userProfiles, 'type','commonAdmin.modal.user.');
                    model.userDefaultEntityProfiles = transformProfiles(model.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                    $scope.callResetList(self);
                    $scope.getUserMailAlerts(self);
                    self.definitionProfiles.containerObject = model;
                    self.definitionDefaultProfiles.containerObject = model;
                    if(error.data.message == 'Other user uses these abbreviation not found') {
                        self.alerts = [{msg: 'global.literals.otherUserWhithAcronym'}];
                    }else if(error.data.message == 'Other user uses these identifier not found'){
                        self.alerts = [{msg: 'global.literals.otherUserWhithIdentifier'}];
                    }else{
                        self.alerts = [{msg: 'global.errors.unknownNew'}];
                    }
            });
        };

        $scope.updateUser = function(val, prop) {
            var self = this;
            var model = angular.copy(this.data);
            var isSection = false;
            var isPosition = false;
            var isRole = false;
            
            // Se quita el que esta en blanco ya que da error en el servidor porque no existe.
            var userDefaultEntityProfiles = []
            angular.forEach(model.userDefaultEntityProfiles, function (defaultEntityProfiles) {
            	if(defaultEntityProfiles.profile.id > 0) {
            		userDefaultEntityProfiles.push(defaultEntityProfiles);
            	}
            });
            model.userDefaultEntityProfiles = userDefaultEntityProfiles;
            
            if(prop == 'userSections') {
                model.userSections = [];
                angular.forEach(val, function (val) {
                    model.userSections.push({ section: val, user: {id:$rootScope.LoggedUser.id}});
                });
                isSection = true;
            } else if(prop == 'userPositions') {
                model.userPositions = [];
                _.forEach(val, function (val) {
                    model.userPositions.push({ position: val, user: { id: $rootScope.LoggedUser.id }});
                });
                isPosition = true
            } else if(prop == 'userRoles') {
                model.userRoles = [];
                angular.forEach(val, function (val) {
                    model.userRoles.push({ role: val, user: {id:$rootScope.LoggedUser.id}});
                })
                isRole = true;
            } else if(prop == 'email') {
                if(val) {
                    if (HelperService.validarEmail(val)) {
                        model[prop] = val;
                    } else {
                        return $filter('translate')("global.validation.email");
                    }
                }else{
                    return $filter('translate')("global.validation.required");
                }
            } else if(prop){
                model[prop] = val;
            }
            callUpdateUserService(model, self, undefined, undefined, isSection, isRole, isPosition);
        }

        $scope.replacePhotoModal = function (user, callerModal) {
            var modal = globalModals.uploadFile;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.user = user;
            modal.callerModal = callerModal;
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'image/gif, image/jpeg, image/png\'';
            AnnexaFormlyFactory.showModal('modalUploadPhoto', modal, $scope.replacePhoto, false);
        }

        $scope.replacePhoto = function () {
            if(this.annexaFormly.form.$valid) {
                var self = this;
                var fileToAdd = {fileName: this.annexaFormly.model.modal_body.file.name, fileType: this.annexaFormly.model.modal_body.file.type };
                var reader = new FileReader();
                reader.onload = function (e) {
                    fileToAdd.file = CommonService.base64ArrayBuffer(e.target.result);
                    callUpdateUserService(self.callerModal.data, self.callerModal, undefined, fileToAdd);
                    self.close();
                }
                reader.readAsArrayBuffer(this.annexaFormly.model.modal_body.file);
            }
        }

        $scope.printSections = function (model) {
            var sections = '';

            angular.forEach(model, function (val, key) {
                if(sections != '') {
                    sections += ', ';
                }

                sections += val[$scope.languageColumn];
            });

            return sections;
        }
        
        $scope.printPositions = function (model) {
            var positions = '';

            _.forEach(model, function (val) {
                if(positions != '') {
                    positions += ', ';
                }

                positions += val[$scope.languageColumn];
            });

            return positions;
        }

        $scope.printRoles = function (model) {
            var roles = '';

            angular.forEach(model, function (val, key) {
                if(roles != '') {
                    roles += ', ';
                }

                roles += val[$scope.languageColumn];
            });

            return roles;
        }

        $scope.openUser = function (id) {
            if(id) {
                var langs = [];
                angular.forEach($rootScope.app.languagedef, function (value) {
                    langs.push({ acronym: value.acronym, name: value.label });
                });

                RestService.findOne('User', id)
                    .then(function (data, third) {
                        var secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec'").toArray();
                        var modal = angular.copy(CommonAdminModals.userEdit);

                        modal.data = JSOG.decode(data.data);
                        modal.data.userProfiles = transformProfiles(modal.data.userProfiles, 'type','commonAdmin.modal.user.');
                        modal.data.userDefaultEntityProfiles = transformProfiles(modal.data.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                        modal.updateUser = $scope.updateUser;
                        modal.haveSec = ((secModule && secModule.length > 0)? true : false);
                        modal.replacePhotoModal = $scope.replacePhotoModal;
                        modal.nameColumn = 'name';
                        modal.languages = langs;
                        modal.languageColumn = $scope.languageColumn;
                        modal.sections = new SortedArray(AdminFactory.sections, $scope.languageColumn).sort();
                        modal.printSections = $scope.printSections;
                        modal.positions = new SortedArray(AdminFactory.positions, $scope.languageColumn).sort();
                        modal.printPositions = $scope.printPositions;
                        modal.politicalParties = new SortedArray(AdminFactory.politicalParties, $scope.languageColumn).sort();
                        modal.roles = new SortedArray(AdminFactory.roles, $scope.languageColumn).sort();
                        modal.printRoles = $scope.printRoles;
						modal.openModalUpdateIdentifier = function() {
			                var saveModalUpdateIdentifier = function(data){
				        	    if(this.annexaFormly.form.$valid) {
					                var modalIdentifierAux = this;
					                var model = modalIdentifierAux.annexaFormly.model.modal_body;
			                        if(model.newPassword != model.rePassword) {
			                            modalIdentifierAux.alerts.push({ msg: 'global.errors.changeIdentifierUserErrorPassword' });
			                        } else {
			                            $http({
			                                url: './api/users/change_identifier/' + modal.data.id,
			                                method: 'PUT',
			                                data: {
			                                	identifier: model.identifier,
												password: model.newPassword
			                                }
			                            }).then(function (data) {
			                                if(data.data) {
			                                    modalIdentifierAux.close();
							 					modal.data = JSOG.decode(data.data);
							                    modal.data.userProfiles = transformProfiles(modal.data.userProfiles, 'type','commonAdmin.modal.user.');
							                    modal.definitionProfiles.items = modal.data.userProfiles;
							                    modal.data.userDefaultEntityProfiles = transformProfiles(modal.data.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
							                    $scope.callResetList(modal);
							                    $scope.getUserMailAlerts(modal);
							                    modal.definitionDefaultProfiles.items = modal.data.userDefaultEntityProfiles;
							                    modal.definitionProfiles.containerObject = modal.data;
							                    modal.definitionDefaultProfiles.containerObject = modal.data;
							                    $scope.tableUser.reloadInternalData(false);
			                                } else {
			                                    modalIdentifierAux.alerts.push({ msg: 'global.errors.changeIdentifierUserError' });
			                                }
			                            }).catch(function (error) {
			                            	if(error && error.data && error.data.message == 'Other user uses these identifier not found'){
                    						    modalIdentifierAux.alerts.push({msg: 'global.literals.otherUserWhithIdentifier'});
                    						}else if(error && error.data && error.data.message == "Password invalid format exception." && GlobalDataFactory.passwordErrorMessage){
			                            		modalIdentifierAux.alerts.push({ msg: GlobalDataFactory.passwordErrorMessage });
			                            	}else{
			                            		modalIdentifierAux.alerts.push({ msg: 'global.errors.changeIdentifierUserError' });
			                            	}
			                            })
			                        }
					            }
			                }

							var modalIdentifier = angular.copy(CommonAdminModals.changeIdentifierUser);
				            modalIdentifier.annexaFormly.model = {};
				            modalIdentifier.annexaFormly.model.modal_body = {};
				            modalIdentifier.annexaFormly.options = {};
				            modalIdentifier.annexaFormly.options.formState = { readOnly: false };
				            modalIdentifier.alerts = [];
				            AnnexaFormlyFactory.showModal('modalChangePassword', modalIdentifier, saveModalUpdateIdentifier, false);			                
			            }
                        modal.definitionProfiles = new editableTable(
                            "profiles",
                            "global.literals.profiles",
                            "global.commonAdmin.modal.user.addProfile",
                            function () {
                                this.inserted = {
                                    profile: { id: -1, language1: '', language2: '', language3: '' },
                                    roles: []
                                };

                                this.items.push(this.inserted);
                            },

                            function (rowform, index, cancel) {
                                var self = this;
                                modal.definitionProfiles.showTableError = false;
                                modal.definitionProfiles.tableErrorText = "";
                                if(cancel) {
                                    if(self.items[index] && !self.items[index].id){
                                        self.items.splice(index, 1);
                                    }
                                    rowform.$cancel();
                                } else {
                                    var exist = false;
                                    if(index && self.items[index]) {
                                        angular.forEach(self.items, function (value, key) {
                                            if (value.profile.id == self.items[index].profile.id && key != index) {
                                                exist = true;
                                            }
                                        });
                                    }
                                    if(exist) {
                                        modal.definitionProfiles.showTableError = true;
                                        modal.definitionProfiles.tableErrorText = $filter("translate")("global.literals.duplicateProfileUser");
                                        return "Error";
                                    }else{
                                        var model = this.containerObject;
                                        model.userProfiles =  transformProfilesToServerProfile(this.items,'type');
                                        model.userDefaultEntityProfiles = transformProfilesToServerProfile(model.userDefaultEntityProfiles,'entityType');
                                        model.userSections = [];
                                        angular.forEach( model.sections, function (val) {
                                            model.userSections.push({ section: val, user: {id:$rootScope.LoggedUser.id}});
                                        });
                                        model.userRoles = [];
                                        angular.forEach(model.roles, function (val) {
                                            model.userRoles.push({ role: val, user: {id:$rootScope.LoggedUser.id}});
                                        });
                                        AdminFactory.updateUser(model)
                                            .then(function (data) {
                                                modal.data = JSOG.decode(data.data);
                                                modal.data.userProfiles = transformProfiles(modal.data.userProfiles, 'type','commonAdmin.modal.user.');
                                                modal.data.userDefaultEntityProfiles = transformProfiles(modal.data.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                                                self.items = modal.data.userProfiles;
                                                modal.definitionDefaultProfiles.items = modal.data.userDefaultEntityProfiles;
                                                $scope.callResetList(modal);
                                                $scope.getUserMailAlerts(modal);
                                                self.containerObject = modal.data;
                                                modal.definitionDefaultProfiles.containerObject = modal.data;
                                        }).catch(function (error) {
                                            model.userProfiles = transformProfiles(model.userProfiles, 'type','commonAdmin.modal.user.');
                                            model.userDefaultEntityProfiles = transformProfiles(model.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                                            self.containerObject = model;
                                            modal.definitionDefaultProfiles.containerObject = model;
                                            $scope.callResetList(modal);
                                            $scope.getUserMailAlerts(modal);
                                        });
                                    }
                                }
                            },

                            function ($index) {
								var self = this;
								var deleteProfile = function($index){								
	                                modal.definitionProfiles.showTableError = false;
	                                modal.definitionProfiles.tableErrorText = "";
	                                var model = self.containerObject;
	                                self.items[$index].deleted  = true;
	                                self.items[$index].removedDate = new Date();
	
	                                model.userProfiles = transformProfilesToServerProfile(self.items,'type');
	                                model.userDefaultEntityProfiles = transformProfilesToServerProfile(model.userDefaultEntityProfiles,'entityType');
	                                model.userSections = [];
	                                angular.forEach( model.sections, function (val) {
	                                    model.userSections.push({ section: val, user: {id:$rootScope.LoggedUser.id}});
	                                });
	                                model.userRoles = [];
	                                angular.forEach(model.roles, function (val) {
	                                    model.userRoles.push({ role: val, user: {id:$rootScope.LoggedUser.id}});
	                                });
	                                AdminFactory.updateUser(angular.copy(model)).then(function (data) {
	                                    modal.data = JSOG.decode(data.data);
	                                    modal.data.userProfiles = transformProfiles(modal.data.userProfiles, 'type','commonAdmin.modal.user.');
	                                    modal.data.userDefaultEntityProfiles = transformProfiles(modal.data.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
	                                    self.items = modal.data.userProfiles;
	                                    modal.definitionDefaultProfiles.items = modal.data.userDefaultEntityProfiles;
	                                    $scope.callResetList(modal);
	                                    $scope.getUserMailAlerts(modal);
	                                    self.containerObject = modal.data;
	                                    modal.definitionDefaultProfiles.containerObject = modal.data;
	                                }).catch(function (error) {
	                                    model.userProfiles = transformProfiles(model.userProfiles, 'type','commonAdmin.modal.user.');
	                                    model.userDefaultEntityProfiles = transformProfiles(model.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
	                                    self.containerObject = model;
	                                    modal.definitionDefaultProfiles.containerObject = model;
	                                    $scope.callResetList(modal);
	                                    $scope.getUserMailAlerts(modal);
	                                });
								}
								if(modal.definitionDefaultProfiles && modal.definitionDefaultProfiles.items && self && self.items && self.items.length > $index && this.items[$index].profile && this.items[$index].profile.id){
									var def = $linq(modal.definitionDefaultProfiles.items).firstOrDefault(undefined,"x => x.profile && x.profile.id == "+self.items[$index].profile.id);
									if(def && def.id){
										DialogsFactory.confirm('global.literals.deleteUserProfileMessage', 'global.literals.deleteUserProfileMessageConfirm').then(function (data) {
								            deleteProfile($index);
							           }).catch(function (data) {
								       });
									}else{
										deleteProfile($index);
									}
								}else{
									deleteProfile($index);
								}

                            },modal.data)
                            .setItems(modal.data.userProfiles)
                            .addColumns([
                                new editableTableColumnDefaultProfile(
                                    'profile',
                                    'global.literals.profile',
                                    '30%',
                                    function (item) {
                                        return item.profile[$scope.languageColumn];
                                    })
                                    .setSelectColumn(
                                        $scope.languageColumn,
                                        new SortedArray(AdminFactory.profiles, $scope.languageColumn).sort(),
                                        function ($data, item, object) {
                                            if($data.id != -1) {
                                                item.profile = $data;
                                            } else {
                                                return $filter('translate')('global.validation.required');
                                            }
                                        }
                                        ,undefined
                                        ,function(){
                                            var profiles = new SortedArray(AdminFactory.profiles, $scope.languageColumn).sort();
                                            if (profiles && modal && modal.data.userProfiles && modal.data.userProfiles.length > 0) {
                                                return $linq(profiles).except(modal.data.userProfiles, function (y, x) {
                                                    if (x.id && y.profile && y.profile.id) {
                                                        return (x.id == y.profile.id);
                                                    }
                                                    else {
                                                        return false;
                                                    }
                                                }).where("x => x.expiryDate == null").toArray();
                                            }
                                            else {
                                                return $linq(profiles).where("x => x.expiryDate == null").toArray();
                                            }
                                        }
                                        ,'falso'
                                    ),
                                new editableTableColumnDefaultProfile(
                                    'roles',
                                    'global.literals.rols',
                                    '62%',
                                    function (roles) {
                                        var result = '';

                                        angular.forEach(roles.roles, function (role) {
                                            if(result != '') {
                                                result += ', ';
                                            }
                                            result += role[Language.getActiveColumn()];
                                        });

                                        return result;
                                    })
                                    .setSelectColumn(
                                        Language.getActiveColumn(),
                                        new SortedArray(AdminFactory.roles, $scope.languageColumn).sort(),
                                        function($data, profile, object) {
                                            profile.roles = [];

                                            if($data) {
                                                profile.roles = $data;
                                            }
                                        },
                                        true,
                                        undefined,
                                        'falso'
                                    )
                            ]);
                        modal.definitionDefaultProfiles = new editableTable(
                            "defaultProfiles",
                            "global.profile.defaultEntityProfiles",
                            "global.commonAdmin.modal.user.addDefaultEntityProfile",
                            function () {

                                this.columns[0].elements = $linq(this.containerObject.userProfiles).where(function(x){ return true }).select(function(x) { return x.profile }).toArray();
                                this.inserted = {
                                    profile: { id: -1, language1: '', language2: '', language3: '' },
                                    entityType: {id: '', description: ''}
                                };
                                this.items.push(this.inserted);
                            },

                            function (rowform, index, cancel) {
                                var self = this;
                                modal.definitionDefaultProfiles.showTableError = false;
                                modal.definitionDefaultProfiles.tableErrorText = "";
                                if(cancel) {
                                    if(self.items[index] && !self.items[index].id){
                                        self.items.splice(index, 1);
                                    }
                                    rowform.$cancel();
                                } else {
                                    var exist = false;
                                    if(index && self.items[index]) {
                                        angular.forEach(self.items, function (value, key) {
                                            if (value.entityType.id == self.items[index].entityType.id && key != index) {
                                                exist = true;
                                            }
                                        });
                                    }
                                    if(exist) {
                                        modal.definitionDefaultProfiles.showTableError = true;
                                        modal.definitionDefaultProfiles.tableErrorText = $filter("translate")("global.literals.duplicateProfileUser");
                                        return "Error";
                                    }else{
                                        var model = this.containerObject;
                                        model.userProfiles = transformProfilesToServerProfile(model.userProfiles,'type');
                                        model.userDefaultEntityProfiles = transformProfilesToServerProfile(this.items,'entityType');
                                        model.userSections = [];
                                        angular.forEach( model.sections, function (val) {
                                            model.userSections.push({ section: val, user: {id:$rootScope.LoggedUser.id}});
                                        });
                                        model.userRoles = [];
                                        angular.forEach(model.roles, function (val) {
                                            model.userRoles.push({ role: val, user: {id:$rootScope.LoggedUser.id}});
                                        })

                                        AdminFactory.updateUser(model)
                                            .then(function (data) {
                                                modal.data = JSOG.decode(data.data);
                                                modal.data.userProfiles = transformProfiles(modal.data.userProfiles, 'type','commonAdmin.modal.user.');
                                                modal.definitionProfiles.items = modal.data.userProfiles;
                                                modal.data.userDefaultEntityProfiles = transformProfiles(modal.data.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                                                self.items = modal.data.userDefaultEntityProfiles;
                                                $scope.callResetList(modal);
                                                $scope.getUserMailAlerts(modal);
                                                self.containerObject = modal.data;
                                                modal.definitionProfiles.containerObject = modal.data;
                                            }).catch(function (error) {
                                                model.userProfiles = transformProfiles(model.userProfiles, 'type','commonAdmin.modal.user.');
                                                model.userDefaultEntityProfiles = transformProfiles(model.userDefaultEntityProfiles, 'entityType','global.commonAdmin.modal.defaultEntityProfile.');
                                                self.containerObject = model;
                                                modal.definitionProfiles.containerObject = model;
                                                $scope.callResetList(modal);
                                                $scope.getUserMailAlerts(modal);
                                        });
                                    }
                                }
                            },

                            function ($index) {
                                var self = this;
                                modal.definitionDefaultProfiles.showTableError = false;
                                modal.definitionDefaultProfiles.tableErrorText = "";
                                var self = this;
                                var model = this.containerObject;
                                model.userDefaultEntityProfiles = [];
                                this.items[$index].deleted  = true;
                                this.items[$index].removedDate = new Date();
                                model.userProfiles = transformProfilesToServerProfile(model.userProfiles,'type');
                                model.userDefaultEntityProfiles = transformProfilesToServerProfile(this.items,'entityType');
                                model.userSections = [];
                                angular.forEach( model.sections, function (val) {
                                    model.userSections.push({ section: val, user: {id:$rootScope.LoggedUser.id}});
                                });
                                model.userRoles = [];
                                angular.forEach(model.roles, function (val) {
                                    model.userRoles.push({ role: val, user: {id:$rootScope.LoggedUser.id}});
                                })
                                AdminFactory.updateUser(angular.copy(model)).then(function (data) {
                                    modal.data = JSOG.decode(data.data);
                                    modal.data.userProfiles = transformProfiles(modal.data.userProfiles, 'type','commonAdmin.modal.user.');
                                    modal.definitionProfiles.items = modal.data.userProfiles;
                                    modal.data.userDefaultEntityProfiles = transformProfiles(modal.data.userDefaultEntityProfiles, 'entityType', 'global.commonAdmin.modal.defaultEntityProfile.');
                                    self.items = modal.data.userDefaultEntityProfiles;
                                    $scope.callResetList(modal);
                                    $scope.getUserMailAlerts(modal);
                                    self.containerObject = modal.data;
                                    modal.definitionProfiles.containerObject = modal.data;
                                }).catch(function (error) {
                                    model.userProfiles = transformProfiles(model.userProfiles, 'type','commonAdmin.modal.user.');
                                    model.userDefaultEntityProfiles = transformProfiles(model.userDefaultEntityProfiles, 'entityType', 'global.commonAdmin.modal.defaultEntityProfile.');
                                    self.containerObject = model;
                                    modal.definitionProfiles.containerObject = model;
                                    $scope.callResetList(modal);
                                    $scope.getUserMailAlerts(modal);
                                });
                            },modal.data)
                            .setItems(modal.data.userDefaultEntityProfiles)
                            .addColumns([
                                new editableTableColumnDefaultProfile(
                                    'profile',
                                    'global.literals.profile',
                                    '46%',
                                    function (item) {
                                        return item.profile[$scope.languageColumn];
                                    })
                                    .setSelectColumn(
                                        $scope.languageColumn,
                                        new SortedArray(AdminFactory.profiles, $scope.languageColumn).sort(),
                                        function ($data, item, object) {
                                            if($data && $data.id != -1) {
                                                item.profile = $data;
                                            } else {
                                                return $filter('translate')('global.validation.required');
                                            }
                                        },
                                        undefined,
                                        undefined,
                                        'verdad'
                                    ),
                                new editableTableColumnDefaultProfile(
                                    'entityType',
                                    'global.profile.entityType',
                                    '46%',
                                    function (item) {
                                        if(item.entityType instanceof Object) {
                                            return $filter('translate')('global.commonAdmin.modal.defaultEntityProfile.' + item.entityType.id);
                                        } else {
                                            return $filter('translate')('global.commonAdmin.modal.defaultEntityProfile.' + item.entityType);
                                        }
                                    })
                                    .setSelectColumn(
                                        'description',

                                        [
                                            {id:'REG', description:'global.commonAdmin.modal.defaultEntityProfile.REG'},
                                            {id:'DOC', description:'global.commonAdmin.modal.defaultEntityProfile.DOC'},
                                            {id:'EXP', description:'global.commonAdmin.modal.defaultEntityProfile.EXP'},
                                            {id:'SEC', description:'global.commonAdmin.modal.defaultEntityProfile.SEC'}
                                        ],

                                        function ($data, item, object) {
                                            if(!$data || !$data.id) {
                                                return $filter('translate')('global.validation.required');
                                            } else {
                                                item.entityType = $data;
                                            }
                                        }
                                        ,undefined
                                        ,_.memoize(function() {
                                            $scope.entidad = [
                                                {id:'REG', description:'global.commonAdmin.modal.defaultEntityProfile.REG'},
                                                {id:'DOC', description:'global.commonAdmin.modal.defaultEntityProfile.DOC'},
                                                {id:'EXP', description:'global.commonAdmin.modal.defaultEntityProfile.EXP'},
                                                {id:'SEC', description:'global.commonAdmin.modal.defaultEntityProfile.SEC'}
                                            ];
                                            if ($scope.entidad && modal.data.userDefaultEntityProfiles && modal.data.userDefaultEntityProfiles.length > 0) {
                                                return $linq($scope.entidad).except(modal.data.userDefaultEntityProfiles, function (y, x) {
                                                    if (x.id && y.entityType && y.entityType.id) {
                                                        return (x.id == y.entityType.id);
                                                    }
                                                    else {
                                                        return false;
                                                    }
                                                }).toArray();
                                            }
                                            return scope.entidad;
                                        })
                                        ,'falso'
                                    )
                            ]);
                        modal.uploadCertificate = function(file, errFiles) {
                            var self = this;
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var fileToAdd = { file: CommonService.base64ArrayBuffer(e.target.result), fileName: file.name.trim().replace(/[^A-Za-z0-9_.()-]/g,""), fileType: file.type };
                                callUpdateUserService(modal.data, self, fileToAdd);
                            }
                            reader.readAsArrayBuffer(file);
                        };

                        modal.removeCertificate = function (index) {
                            var self = this;
                            modal.data.userCertificates[index].deleted = true;
                            modal.data.userCertificates[index].removedDate = new Date();
                            callUpdateUserService(modal.data, self, undefined);
                        };
                        
                        modal.getDateNotAfterClass = function (dateNotAfter) {
    	                	var textClass = "";	                	
    	                	if (dateNotAfter) {
    		                	var today = new Date();
    		                	var difference = dateNotAfter - today.getTime();
    		                	var days = Math.ceil(difference / (1000 * 3600 * 24));
    		                	
    		                	if (days <= 0) {
    		                		textClass = "fa fa-warning text-danger"
    		                	} else if (days > 0 && days <= 30) {
    		                		textClass = "fa fa-warning text-warn"
    		                	}
    	                	}
    	                	
    	                    return textClass;
    	                };
                        
                        modal.changeUserMailAlert = function(userAlertTypeEnum,status) {
                        	if((modal.data.documentsToSignOrValidate != null && modal.data.documentsToSignOrValidate != undefined) ||
                        			(modal.data.diligenceChanges != null && modal.data.diligenceChanges != undefined) ||
                        			(modal.data.dossierChanges != null && modal.data.dossierChanges != undefined) ||
                        			(modal.data.proposalChanges != null && modal.data.proposalChanges != undefined) ||
                        			(modal.data.taskChanges != null && modal.data.taskChanges != undefined) ||
                        			(modal.data.notificationChanges != null && modal.data.notificationChanges != undefined)){
                        		CommonAdminService.updateUserAlertMail(userAlertTypeEnum,status,modal.data.id);
                        	}
                        };
                        
                        $scope.getUserMailAlerts(modal);
                        
                        AnnexaModalFactory.showModal('modalUserEdit', modal);

                        $scope.callResetList(modal);
                    });

            }
        };
        
        $scope.getUserMailAlerts = function(modal){
        	if (modal.data && modal.data.id) {
                /*si se agregan nuevos avisos, agregar en los case los avisos tal cual se definen en UserAlertTypeEnum y relacionarlos con los checkbox*/
            	CommonAdminService.getUserMailAlerts(modal.data.id).then(function (data){
            		var userMailAlerts = data;
            		if(userMailAlerts){
            			angular.forEach(userMailAlerts, function (userMailAlert, key) {
        	        		switch (userMailAlert.alertType){
        	        			case 'DOCUMENT_SIGNATURE':
        	        				modal.data.documentsToSignOrValidate = true;
        	        				break;
        	        			case 'DILIGENCE':
        	        				modal.data.diligenceChanges = true;
        	        				break;
        	        			case 'DOSSIER':
        	        				modal.data.dossierChanges = true;
        	        				break;
        	        			case 'PROPOSAL':
        	        				modal.data.proposalChanges = true;
        	        				break;
        	        			case 'TASK':
        	        				modal.data.taskChanges = true;
        	        				break;
        	        			case 'NOTIFICATION':
        	        				modal.data.notificationChanges = true;
        	        				break;
        	        		}
            			});
            		}
                }).catch(function (error) {
                	
                });        		
        	}
        }
        
        $scope.callResetList = function(modal){
            if(modal.data) {
                modal.data.sections = [];
                angular.forEach(modal.data.userSections, function (value, key) {
                    var objs = $linq(AdminFactory.sections).where("x => x.id == '" + value.section.id+"'").toArray();
                    if(objs && objs.length > 0) {
                        modal.data.sections.push(objs[0]);
                    }
                });
                modal.data.positions = [];
                _.forEach(modal.data.userPositions, function (value) {
                    var objs = $linq(AdminFactory.positions).where("x => x.id == '" + value.position.id + "'").toArray();
                    if(objs && objs.length > 0) {
                        modal.data.positions.push(objs[0]);
                    }
                });
                modal.data.roles = [];
                angular.forEach(modal.data.userRoles, function (value, key) {
                    var objs = $linq(AdminFactory.roles).where("x => x.id == '" + value.role.id+"'").toArray();
                    if(objs && objs.length > 0) {
                        modal.data.roles.push(objs[0]);
                    }
                });
                if(modal.definitionProfiles){
                    angular.forEach(modal.definitionProfiles.items, function(value1, key){
                        angular.forEach(modal.definitionProfiles.columns, function(value2, key){
                            if(value2.type == 'select-multiple') {
                                var obj = HelperService.selectSelected(value2.elements, value1[value2.key], undefined, false, true);
                                if (obj && obj.length > 0) {
                                    value1[value2.key] = obj;
                                }
                            }else if(value2.type == 'select'){
                                var obj = HelperService.selectSelected(value2.elements, value1[value2.key], undefined, false, false);
                                if (obj && obj.length > 0) {
                                    value1[value2.key] = obj[0];
                                }
                            }
                        });
                    });
                }
                if(modal.definitionDefaultProfiles) {
                    angular.forEach(modal.definitionDefaultProfiles.items, function (value1, key) {
                        angular.forEach(modal.definitionDefaultProfiles.columns, function (value2, key) {
                            if (value2.type == 'select-multiple') {
                                var obj = HelperService.selectSelected(value2.elements, value1[value2.key], undefined, false, true);
                                if (obj && obj.length > 0) {
                                    value1[value2.key] = obj;
                                }
                            } else if (value2.type == 'select') {
                                var obj = HelperService.selectSelected(value2.elements, value1[value2.key], undefined, false, false);
                                if (obj && obj.length > 0) {
                                    value1[value2.key] = obj[0];
                                }
                            }
                        });
                    });
                }
                if(modal.data.language) {
                    var obj = HelperService.selectSelected(modal.languages, modal.data.language, 'language', false, false);
                    if (obj && obj.length > 0) {
                        modal.data.language = obj[0];
                    }
                }
            }
        };
        $scope.disabledUser = function (id){
            var content = {
                id:id,
                expiryDate:new Date()
            };
            CommonAdminService.updateObject(apiAdmin.getUsers,id,content).then(function (dataUpdate) {
                $scope.tableUser.reloadInternalData(true);
            }).catch(function () {
            });
        }

        $scope.enabledUser = function (id){
            var content = {
                id:id,
                expiryDate:null,
                numAccessError:0,
                lastAccessDate:new Date()
            };
            CommonAdminService.updateObject(apiAdmin.getUsers,id,content).then(function (dataUpdate) {
                $scope.tableUser.reloadInternalData(true);
            }).catch(function () {
            });
        }

    }]);
